<template>
  <div class="cz-header">
    <v-app-bar
      :color="backgroundColor"
      :height="$vuetify.breakpoint.mdAndUp ? 100 : 80"
      fixed
    >
      <CZMusicButton class="ml-4" v-if="$vuetify.breakpoint.mdAndUp" />

      <v-container class="d-flex pa-0 align-center header-bar">
        <CZHeaderLogoTextBox class="pa-2" @click="scrollTriggerLogo" />
        <CZHeaderLogoSymbolBox
          class="pa-2 ml-4"
          @click.native="scrollTriggerLogo"
          v-if="$vuetify.breakpoint.mdAndUp"
        />
        <v-tabs
          hide-slider
          color="primary lighten-2"
          v-model="activeTabComp"
          background-color="transparent"
          height="100"
          :class="['cz-tabs', scrollClass]"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <!--<v-tab class="pa-0" style="min-width: 0" />-->
          <v-tab
            class="text-body-1 text-uppercase text-size"
            v-for="(section, index) in sections"
            :key="index"
            @click="
              section.name === 'store' ? '' : scrollTrigger(section.id, index)
            "
            :to="getRoute(section.id)"
            :href="section.name === 'store' ? 'https://store.commandz.xyz' : ''"
            :target="section.name === 'store' ? '_blank' : ''"
          >
            {{ section.name }}
          </v-tab>
        </v-tabs>

        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.smAndDown"
          @click.stop="drawer = !drawer"
        >
          <v-icon size="20" color="primary lighten-2"> $hamburger </v-icon>
        </v-app-bar-nav-icon>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      top
      temporary
      class="primary lighten-4"
      dark
    >
      <v-list>
        <v-list-item class="py-12 d-flex align-center justify-center">
          <CZHeaderLogoSymbolBox @click.native="scrollTriggerLogo(true)" />
        </v-list-item>
      </v-list>
      <v-list rounded>
        <v-list-item-group
          v-model="activeTabComp"
          color="primary"
          class="text-center"
        >
          <v-list-item
            v-for="(section, index) in sections"
            :key="index"
            @click="scrollTrigger(section.id, index, true)"
          >
            <v-list-item-title class="text-body-1 text-capitalize">{{
              section.name
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import scrollToMixin from '@/mixins/scrollToMixin'

export default {
  name: 'CZHeader',
  mixins: [scrollToMixin],
  components: {
    CZHeaderLogoSymbolBox: () =>
      import(
        /* webpackChunkName: "header" */ '@/components/header/CZHeaderLogoSymbolBox'
      ),
    CZHeaderLogoTextBox: () =>
      import(
        /* webpackChunkName: "header" */ '@/components/header/CZHeaderLogoTextBox'
      ),
    CZMusicButton: () =>
      import(
        /* webpackChunkName: "header" */ '@/components/header/CZMusicButton'
      )
  },
  props: {
    activeTab: {
      type: Number
    }
  },
  data: () => ({
    activeTabComp: null,
    callToAction: 'get on opensea',
    drawer: false
  }),
  computed: {
    backgroundColor() {
      return 'rgba(5, 5, 5, 0.9)'
    },
    scrollClass() {
      return !this.currentRouteType
        ? // return this.isTransparent && !this.currentRouteType
          'scrolled'
        : 'not-scrolled'
    },
    currentRouteType() {
      return this.$route.meta.static
    }
  },
  methods: {
    //storeTrigger(name) {
    //  if(name==='store') {
    //    this
    //  }
    //},
    selectTab(value) {
      this.setTab(value + 1)
    },
    clickedTab(value) {
      this.$emit('clickedTab', value)
    },
    setTab(value) {
      this.$emit('setTab', value)
    },
    scrollTriggerLogo(mobile = false) {
      if (!this.$route.meta.static) {
        this.setTab(null)
        this.scrollToSection('#cz-hero-top')
        this.clickedTab(true)
        if (mobile) {
          this.drawer = false
        }
      } else {
        this.$router.push('/')
      }
    },
    scrollTrigger(value, index, mobile = false) {
      let tab = mobile ? index : index + 1
      this.setTab(tab)
      this.scrollToSection(value)
      this.clickedTab(true)
    },
    getRoute(sectionId) {
      return this.$route.meta.static ? `/${sectionId}` : ''
    }
  },
  watch: {
    activeTab: function () {
      this.activeTabComp = this.activeTab
      this.drawer = false
    }
  }
  //created() {
  //  window.onpopstate = () => {
  //    if (!this.currentRouteType) {
  //      this.isTransparent = true
  //    }
  //  }
  //}
}
</script>

<style scoped lang="scss">
.cz-header::v-deep {
  z-index: 10 !important;

  & .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
  .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
  .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
  .theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
    color: $primary-base-rgb !important;
  }

  & .v-toolbar__content {
    border-bottom: 2px solid $primary-base-rgb !important;
  }

  &
    .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: $primary-lighten1-rgb;
  }

  //& .text-size {
  //  font-size: 1.05rem !important;
  //  font-weight: bolder;
  //}

  //& .h-tabs {
  //  & .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  //    color: red !important;
  //  }
  //}
}
</style>
