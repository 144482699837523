const palette = {
  primary: {
    base: '#114343', //500
    darken1: '#0D3333', //600
    darken2: '#0B2D2E', //700
    darken3: '#0B2626', //800
    darken4: '#081E1E', //900
    lighten1: '#365555', //400
    lighten2: '#5F7878', //300
    lighten3: '#879A9A', //200
    lighten4: '#B0BDBD', //100
    lighten5: '#D9DFDF' //50 #ff6584
  },
  grey: {
    base: '#CBCDD1', //500
    darken1: '#93989F', //600
    darken2: '#46494e', //700
    darken3: '#323539', //800
    darken4: '#0a0a0b', //900
    lighten1: '#E3E5E7', //400
    lighten2: '#ecedee', //300
    lighten3: '#f4f5f5', //200
    lighten4: '#F7F7F7', //100
    lighten5: '#fafafa' //50
  },
  accent: {
    base: '#993366', //500
    darken1: '#862D59', //600
    darken2: '#73264C', //700
    darken3: '#5F2040', //800
    darken4: '#4C1933', //900
    lighten1: '#AA5580', //400
    lighten2: '#BB789A', //300
    lighten3: '#CD9AB3', //200
    lighten4: '#DEBDCD', //100
    lighten5: '#EFDFE7' //50
  },
  error: {
    base: '#B71D29', //500
    darken1: '#9C1923', //600
    darken2: '#80141D', //700
    darken3: '#651017', //800
    darken4: '#490C10', //900
    lighten1: '#DC2433', //400
    lighten2: '#E24B57', //300
    lighten3: '#E9727B', //200
    lighten4: '#EF989F', //100
    lighten5: '#F1A7AD' //50
  },
  info: {
    base: '#2391EB', //500
    darken1: '#137CD2', //600
    darken2: '#1066AD', //700
    darken3: '#0C5088', //800
    darken4: '#093B63', //900
    lighten1: '#44A2EE', //400
    lighten2: '#65B2F1', //300
    lighten3: '#86C3F4', //200
    lighten4: '#A7D3F7', //100
    lighten5: '#B4DAF8' //50
  },
  success: {
    base: '#3DB971', //500
    darken1: '#349D60', //600
    darken2: '#2B824F', //700
    darken3: '#22663E', //800
    darken4: '#184A2D', //900
    lighten1: '#56C786', //400
    lighten2: '#74D19B', //300
    lighten3: '#92DBB0', //200
    lighten4: '#B0E5C6', //100
    lighten5: '#BAE8CD' //50
  },
  warning: {
    base: '#F6D41E', //500
    darken1: '#E2BF09', //600
    darken2: '#BA9E07', //700
    darken3: '#927C06', //800
    darken4: '#6A5A04', //900
    lighten1: '#F7DA40', //400
    lighten2: '#F9E162', //300
    lighten3: '#FAE783', //200
    lighten4: '#FBEEA5', //100
    lighten5: '#FCF1B6' //50
  }
}

export default {
  //dark: palette, //dark,
  //light: palette //light
  dark: { ...palette, background: '#000000', text: '#f4f4f4' }, //dark,
  light: { ...palette, background: '#f4f4f4', text: '#000000' } //light
}
