<template>
  <v-footer padless>
    <v-card
      flat
      tile
      width="100%"
      class="d-flex align-end footer-card"
      :min-height="$vuetify.breakpoint.smAndDown ? 180 : 160"
    >
      <v-container>
        <v-row></v-row>

        <v-divider class="mb-4 mt-8 footer-divider"></v-divider>

        <v-row
          class="text-body-2 font-weight-light primary--text text--lighten-1"
        >
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
            class="d-flex align-center"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ['justify-center', 'pb-0']
                : ['justify-start']
            "
          >
            <CZCrafted v-if="$vuetify.breakpoint.smAndDown" />
            <p class="ma-0 pa-0" v-else>{{ copyrightTag }}</p>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="5"
            lg="6"
            class="d-flex align-center justify-center"
            :class="$vuetify.breakpoint.smAndDown ? ['pt-1', 'pb-2'] : ''"
          >
            <p class="ma-0 pa-0" v-if="$vuetify.breakpoint.smAndDown">
              {{ copyrightTag }}
            </p>
            <CZCrafted v-else />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="3"
            class="d-flex align-center"
            :class="
              $vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'
            "
          >
            <!-- <HNavLinks :links="links" />-->
            <div></div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
import scrollToMixin from '@/mixins/scrollToMixin'
export default {
  name: 'CZFooter',
  mixins: [scrollToMixin],
  components: {
    //HNavLinks: () =>
    //  import(/* webpackChunkName: "footer" */ '@/components/footer/HNavLinks'),
    CZCrafted: () =>
      import(/* webpackChunkName: "footer" */ '@/components/footer/CZCrafted')
    //HFooterLogoBox: () =>
    //  import(
    //    /* webpackChunkName: "footer" */ '@/components/footer/HFooterLogoBox'
    //  ),
    //HActionButton: () =>
    //  import(
    //    /* webpackChunkName: "product" */ '@/components/sections/HActionButton'
    //  )
  },
  data: () => ({
    callToAction: 'request demo',
    companyName: 'CommandZ Inc.',
    rightsReserved: 'All rights reserved',
    links: ['terms', 'privacy']
  }),
  computed: {
    copyrightTag() {
      return `
      ©${new Date().getFullYear()} -
      ${this.companyName}
      ${this.rightsReserved}
      `
    }
  },
  methods: {
    setTab(value) {
      this.$emit('setTab', value)
    },
    clickedTab(value) {
      this.$emit('clickedTab', value)
    },
    setTabButton(value) {
      this.setTab(value + 1)
    },
    scrollTriggerLogo() {
      if (!this.$route.meta.static) {
        this.setTab(null)
        this.scrollToSection('#h-hero')
        this.clickedTab(true)
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer-card::v-deep {
  position: relative;
  background-color: $black-bg !important;

  & .footer-divider {
    border-color: $primary-base-rgb !important;
  }
  & .footer-links {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
    & li:after {
      margin-right: 4px;
      margin-left: 4px;
      content: ' | ';
    }
    & li:last-child:after {
      content: none;
    }
    & a {
      color: white;
      text-decoration: none;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
</style>
