<template>
  <v-app dark>
    <CZHeader v-if="!this.$route.meta.error" />
    <v-main>
      <router-view />
    </v-main>
    <CZFooter v-if="!this.$route.meta.error" />
  </v-app>
</template>

<script>
import CZHeader from '@/components/CZHeader'
import CZFooter from '@/components/CZFooter'
//import CZEmbededMintButton from '@/components/CZEmbededMintButton'
export default {
  name: 'App',
  components: {
    CZFooter,
    CZHeader
    //CZEmbededMintButton
  },
  data: () => ({
    //
  })
  //computed: {
  //  isError() {
  //   return this.$route.meta.error
  // }
  //}
}
</script>

<style scoped lang="scss">
.theme--dark.v-application {
  background-color: var(--v-background-base, $black-bg) !important;
  color: var(--v-text-base, #ffffff) !important;
}
.theme--light.v-application {
  background-color: var(--v-background-base, #ffffff) !important;
  color: var(--v-text-base, $black-bg) !important;
}
</style>
