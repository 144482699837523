import CoffeeIcon from '@/icons/CoffeeIcon'
import HeartIcon from '@/icons/HeartIcon'
import BrandLinkedinIcon from '@/icons/BrandLinkedinIcon'
import BrandTwitterIcon from '@/icons/BrandTwitterIcon'
import BrandOpenseaIcon from '@/icons/BrandOpenseaIcon'
import BrandDiscordIcon from '@/icons/BrandDiscordIcon'
import BrandGithubIcon from '@/icons/BrandGithubIcon'
import BrandInstagramIcon from '@/icons/BrandInstagramIcon'
import BrandInstagramSquareIcon from '@/icons/BrandInstagramSquareIcon'
import HamburgerIcon from '@/icons/HamburgerIcon'
import CatIcon from '@/icons/CatIcon'
import ChevronRightIcon from '@/icons/ChevronRightIcon'
import DotIcon from '@/icons/DotIcon'
import CheckboxOnIcon from '@/icons/CheckboxOnIcon'
import CheckboxOffIcon from '@/icons/CheckboxOffIcon'
import ChevronDownIcon from '@/icons/ChevronDownIcon'
import FeatherIcon from '@/icons/FeatherIcon'
import MusicOnIcon from '@/icons/MusicOnIcon'
import MusicOffIcon from '@/icons/MusicOffIcon'

export default {
  CoffeeIcon,
  HeartIcon,
  BrandLinkedinIcon,
  BrandTwitterIcon,
  BrandOpenseaIcon,
  BrandDiscordIcon,
  BrandGithubIcon,
  BrandInstagramIcon,
  BrandInstagramSquareIcon,
  HamburgerIcon,
  CatIcon,
  ChevronRightIcon,
  DotIcon,
  CheckboxOnIcon,
  CheckboxOffIcon,
  ChevronDownIcon,
  FeatherIcon,
  MusicOnIcon,
  MusicOffIcon
}
