<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="music-off--icon"
  >
    <path
      d="M7.19992 16.5465C6.80697 16.4512 6.40427 16.4018 5.99992 16.3995C4.01242 16.3995 2.39992 17.4743 2.39992 18.7995C2.39992 20.1248 4.01242 21.1995 5.99992 21.1995C7.98742 21.1995 9.59992 20.1248 9.59992 18.7995V14.295L7.19992 12.4403V16.5465ZM23.7682 19.1783L21.3284 17.2928C21.4975 17.0164 21.5999 16.7172 21.5999 16.3995V3.19953C21.5997 3.01115 21.5552 2.82545 21.4699 2.65747C21.3847 2.48949 21.2611 2.34394 21.1091 2.23261C20.9571 2.12128 20.7811 2.0473 20.5952 2.01664C20.4093 1.98599 20.2188 1.99952 20.0392 2.05616L8.04029 5.59953C7.85182 5.65953 7.6812 5.7654 7.54375 5.90763C7.4063 6.04985 7.30632 6.22399 7.25279 6.41441L1.70504 2.12666C1.64282 2.07819 1.57165 2.04246 1.49561 2.02153C1.41957 2.00059 1.34015 1.99485 1.26188 2.00464C1.18362 2.01442 1.10806 2.03955 1.03951 2.07856C0.970968 2.11758 0.91079 2.16973 0.862419 2.23203L0.126294 3.17966C0.0286433 3.30527 -0.0151133 3.46454 0.00464779 3.62241C0.0244088 3.78029 0.106069 3.92385 0.231669 4.02153L22.2948 21.0732C22.357 21.1216 22.4282 21.1573 22.5042 21.1783C22.5803 21.1992 22.6597 21.205 22.738 21.1952C22.8162 21.1854 22.8918 21.1603 22.9603 21.1212C23.0289 21.0822 23.0891 21.0301 23.1374 20.9678L23.8735 20.0202C23.9712 19.8945 24.015 19.7353 23.9952 19.5774C23.9754 19.4195 23.8938 19.276 23.7682 19.1783V19.1783ZM19.1999 14.1465C18.807 14.0512 18.4043 14.0018 17.9999 13.9995C17.7214 14.0033 17.4436 14.0298 17.1693 14.0787L11.2964 9.54116L19.1999 7.22328V14.1465Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'MusicOffIcon'
}
</script>

<style scoped lang="scss">
.music-off--icon {
  fill: currentColor;
}
</style>
