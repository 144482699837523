<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="feather--icon"
  >
    <path
      d="M21.0727 11.3597L16.0972 13.3749H20.1437C19.8129 14.013 19.445 14.633 19.0341 15.223L13.3509 17.4999H17.0246C14.4793 19.7648 10.8438 20.952 5.67959 19.7695L2.75735 22.697C2.35502 23.1 1.70391 23.0998 1.30159 22.697C0.899471 22.2941 0.899471 21.6417 1.30159 21.2386L12.1705 10.3503C12.3783 10.0807 12.3696 9.69763 12.1227 9.45013C11.8546 9.18158 11.4201 9.18158 11.1525 9.45013L3.92088 16.6947C2.09123 3.49209 16.7749 1.34872 21.5789 1.00353C22.3882 0.945355 23.0548 1.61326 22.9964 2.42365C22.857 4.37424 22.4195 7.95224 21.0727 11.3597Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'FeatherIcon'
}
</script>

<style scoped lang="scss">
.feather--icon {
  fill: currentColor;
}
</style>
