import icons from '@/icons'

export default {
  heart: {
    component: icons.HeartIcon
  },
  coffee: {
    component: icons.CoffeeIcon
  },
  brandTwitter: {
    component: icons.BrandTwitterIcon
  },
  brandLinkedin: {
    component: icons.BrandLinkedinIcon
  },
  brandDiscord: {
    component: icons.BrandDiscordIcon
  },
  brandGithub: {
    component: icons.BrandGithubIcon
  },
  brandInstagram: {
    component: icons.BrandInstagramIcon
  },
  brandInstagramSquare: {
    component: icons.BrandInstagramSquareIcon
  },
  brandOpensea: {
    component: icons.BrandOpenseaIcon
  },
  hamburger: {
    component: icons.HamburgerIcon
  },
  cat: {
    component: icons.CatIcon
  },
  chevronRight: {
    component: icons.ChevronRightIcon
  },
  dot: {
    component: icons.DotIcon
  },
  checkboxOn: {
    component: icons.CheckboxOnIcon
  },
  checkboxOff: {
    component: icons.CheckboxOffIcon
  },
  chevronDown: {
    component: icons.ChevronDownIcon
  },
  Feather: {
    component: icons.FeatherIcon
  },
  musicOn: {
    component: icons.MusicOnIcon
  },
  musicOff: {
    component: icons.MusicOffIcon
  }
}
