<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="chevron-down--icon"
  >
    <path
      d="M20.2617 8.53522L19.4883 7.76178C19.3047 7.57819 19.0078 7.57819 18.8242 7.76178L11.75 14.8204L4.67578 7.76178C4.49219 7.57819 4.19531 7.57819 4.01172 7.76178L3.23828 8.53522C3.05469 8.71881 3.05469 9.01569 3.23828 9.19928L11.418 17.379C11.6016 17.5626 11.8984 17.5626 12.082 17.379L20.2617 9.19928C20.4453 9.01569 20.4453 8.71881 20.2617 8.53522V8.53522Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChevronDownIcon'
}
</script>

<style scoped lang="scss">
.chevron-down--icon {
  fill: currentColor;
  //path {
  //  stroke: currentColor;
  //}
}
</style>
