<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="music-on--icon"
  >
    <path
      d="M22.0491 0.0693867L7.05047 4.49861C6.74601 4.59424 6.47999 4.78449 6.29107 5.04169C6.10216 5.29889 6.00019 5.60964 6 5.92876V18.1824C5.50882 18.0631 5.00543 18.0015 4.5 17.9986C2.01562 17.9986 0 19.342 0 20.9986C0 22.6552 2.01562 23.9986 4.5 23.9986C6.98438 23.9986 9 22.6552 9 20.9986V10.0449L21 6.52923V15.1828C20.5089 15.0632 20.0055 15.0014 19.5 14.9986C17.0156 14.9986 15 16.342 15 17.9986C15 19.6552 17.0156 20.9986 19.5 20.9986C21.9844 20.9986 24 19.6556 24 17.9986V1.49861C23.9998 1.26312 23.9441 1.031 23.8375 0.821027C23.7309 0.611051 23.5764 0.42912 23.3865 0.289958C23.1965 0.150796 22.9764 0.0583133 22.7441 0.0199938C22.5118 -0.0183257 22.2737 -0.00140487 22.0491 0.0693867V0.0693867Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'MusicOnIcon'
}
</script>

<style scoped lang="scss">
.music-on--icon {
  fill: currentColor;
}
</style>
