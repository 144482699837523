import themes from '@/plugins/commandz-themes'
import icons from '@/plugins/commandz-icons'

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: icons
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: themes
  }
})
