<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="brand-instagram--icon"
  >
    <path
      d="M11.5082 8.11758C8.52695 8.11758 6.12227 10.5223 6.12227 13.5035C6.12227 16.4848 8.52695 18.8895 11.5082 18.8895C14.4895 18.8895 16.8941 16.4848 16.8941 13.5035C16.8941 10.5223 14.4895 8.11758 11.5082 8.11758ZM11.5082 17.0051C9.58164 17.0051 8.00664 15.4348 8.00664 13.5035C8.00664 11.5723 9.57695 10.002 11.5082 10.002C13.4395 10.002 15.0098 11.5723 15.0098 13.5035C15.0098 15.4348 13.4348 17.0051 11.5082 17.0051V17.0051ZM18.3707 7.89727C18.3707 8.5957 17.8082 9.15352 17.1145 9.15352C16.416 9.15352 15.8582 8.59102 15.8582 7.89727C15.8582 7.20352 16.4207 6.64102 17.1145 6.64102C17.8082 6.64102 18.3707 7.20352 18.3707 7.89727ZM21.9379 9.17227C21.8582 7.48945 21.4738 5.99883 20.241 4.7707C19.0129 3.54258 17.5223 3.1582 15.8395 3.07383C14.1051 2.97539 8.90664 2.97539 7.17227 3.07383C5.49414 3.15352 4.00352 3.53789 2.7707 4.76602C1.53789 5.99414 1.1582 7.48477 1.07383 9.16758C0.975391 10.902 0.975391 16.1004 1.07383 17.8348C1.15352 19.5176 1.53789 21.0082 2.7707 22.2363C4.00352 23.4645 5.48945 23.8488 7.17227 23.9332C8.90664 24.0316 14.1051 24.0316 15.8395 23.9332C17.5223 23.8535 19.0129 23.4691 20.241 22.2363C21.4691 21.0082 21.8535 19.5176 21.9379 17.8348C22.0363 16.1004 22.0363 10.9066 21.9379 9.17227V9.17227ZM19.6973 19.6957C19.3316 20.6145 18.6238 21.3223 17.7004 21.6926C16.3176 22.241 13.0363 22.1145 11.5082 22.1145C9.98008 22.1145 6.69414 22.2363 5.31602 21.6926C4.39727 21.327 3.68945 20.6191 3.31914 19.6957C2.7707 18.3129 2.89727 15.0316 2.89727 13.5035C2.89727 11.9754 2.77539 8.68945 3.31914 7.31133C3.68477 6.39258 4.39258 5.68477 5.31602 5.31445C6.69883 4.76602 9.98008 4.89258 11.5082 4.89258C13.0363 4.89258 16.3223 4.7707 17.7004 5.31445C18.6191 5.68008 19.327 6.38789 19.6973 7.31133C20.2457 8.69414 20.1191 11.9754 20.1191 13.5035C20.1191 15.0316 20.2457 18.3176 19.6973 19.6957Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BrandInstagramIcon'
}
</script>

<style scoped lang="scss">
.brand-instagram--icon {
  fill: currentColor;
}
</style>
